import { api } from '../api/axios-base'
export const searchUtilsMixin = {
  methods: {
    async searchStudentByName(name = '') {
      try {
        const url = `/personas/buscar-alumno-nombre/${name}`
        const response = await api.get(url)
        const responseData = await response.data
        return responseData.alumno && responseData.alumno.length 
          ? responseData.alumno[0] 
          : null
      } catch (error) {
        console.error('Error al intentar buscar alumno', error);
      }
    }
  }
}