<template>
  <section>
    <v-container>
      <v-row
        class="pt-15"
        justify="center"
        align="center"
      >
        <v-col
          cols="11"
          md="8"
          lg="8"
          xl="5"
        >
          <kn-form-title
            :title="title"
            :routerName="routerName"
          />
          <kn-tabs
            :items="tabs"
            @tabValue="setTabValue" 
          />
          <kn-form-note-of-mandatory />
          <!-- Informacion alumno -->
          <div v-if="valueDeterminate === 33">
            <kn-personal-info :model="studentPersonalInfo" />

            <kn-form-subtitle title="Dirección" />
            <kn-address
              :address="studentAddress"
              :fillFromSearch="fillStudentFromSearch"
            />

            <kn-form-subtitle title="Información escolar" />
            <kn-school-info :model="studentPersonalInfo"/>

            <v-row
              v-if="showGroupActions"
              justify="center"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
              >
                <v-avatar size="164">
                  <v-img
                    v-if="entity.groupId === null"
                    :src="require(`@/assets/images/${entity.avatar}`)"
                  ></v-img>
                  <v-img
                    v-else
                    :src="entity.avatar"
                  ></v-img>
                </v-avatar>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
              >
                <v-card flat>
                  <v-card-title>Grupo - {{ entity.grupo }}</v-card-title>
                  <v-card-text>
                    <p class="mb-1">Ciclo escolar: {{ schoolCycleText }} </p>
                    <p class="mb-1">Colegiatura: {{ tuitionText }} </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <kn-select
              v-if="showGroupActions"
              class="mt-3"
              label="Acciones de grupo"
              v-model="selectedGroupAction"
              :rules="[]"
              :isMandatory="false"
              :items="groupActions"
              item-value="value"
              item-text="text"
            />
            <kn-select
              v-if="(showAssignGroup || showPassGroup)"
              class="mt-4"
              :label="assignGroupText"
              v-model="selectedGroup"
              :rules="[]"
              :items="groups"
              item-text="nombre_grupo"
              item-value="id"
              return-object
              @input="loadGroupInfo"
            />
            <v-row v-if="openChargeInfo && (showAssignGroup || showPassGroup)">
              <v-col 
                class="py-0" 
                cols="12"
                md="4"
                lg="4"
                xl="4"
              >
                <v-subheader>
                  Ciclo escolar<span class="error--text">*</span>
                </v-subheader>
              </v-col>
              <v-col cols="1" class="text-center graydefault--text">Inicio</v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="selectedGroup.ciclo_escolar.fecha_inicio"
                  dense
                  outlined
                  type="date"
                  hide-details
                />
              </v-col>
              <v-col cols="1" class="text-center graydefault--text">Fin</v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="selectedGroup.ciclo_escolar.fecha_fin"
                  dense
                  outlined
                  type="date"
                  hide-details
                />
              </v-col>
            </v-row>
            <kn-select
              v-if="openChargeInfo && (showAssignGroup || showPassGroup)"
              class="mt-6"
              label="Colegiatura"
              v-model="studentPersonalInfo.colegiatura"
              :rules="[rules.required]"
              :items="tuition"
              item-text="nombre_colegiatura"
              item-value="id"
              return-object
            />
            <kn-select
              v-if="openChargeInfo && (showAssignGroup || showPassGroup)"
              class="mt-4"
              label="Descuento pronto pago"
              v-model="studentPersonalInfo.prontoPago"
              :rules="[rules.required]"
              :items="promptPaymentDiscounts"
              item-text="nombre_descuento"
              item-value="id"
              return-object
            />
            <kn-select
              v-if="openChargeInfo && (showAssignGroup || showPassGroup)"
              label="Penalización"
              v-model="studentPersonalInfo.id_penalizacion"
              :rules="[rules.required]"
              :items="penalties"
              item-text="nombre"
              item-value="id"
            />
            <kn-select
              v-if="openChargeInfo && (showAssignGroup || showPassGroup)"
              label="Estatus cargos"
              v-model="studentPersonalInfo.id_estatus_adeudo"
              :rules="[rules.required]"
              :items="debtStatus"
              item-text="dato"
              item-value="id"
            />
            <kn-check-box
              v-if="openChargeInfo && showPassGroup"
              label="Generar cuotas de inscripción?"
              v-model="checkInitialDebts"
              :rules="[]"
              :isMandatory="false"
            />
            <v-card
              v-if="openChargeInfo && showPassGroup && checkInitialDebts"
              class="mx-auto"
              elevation="0"
              tile
              height="200px"
              width="330px"
            >
              <v-simple-table
                fixed-header
                height="300px"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Descripción
                      </th>
                      <th class="text-left">
                        Monto
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in wildcardStudentDebts"
                      :key="item.id"
                    >
                      <td>{{ item.nombre_adeudo }}</td>
                      <td>{{ item.total_adeudo }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>

          </div>
          <!-- Fin informacion alumno -->
          <!-- Informacion de tutor -->
          <div v-if="valueDeterminate === 66">
            <kn-personal-info
              :model="tutorPersonalInfo"
              :isTutor="true"
            >
              <template #searchPerson>
                <v-row>
                  <v-col
                    class="pb-0"
                    cols="12"
                    md="4"
                    lg="4"
                    xl="4"
                  >
                    <v-subheader>Tutor:</v-subheader>
                  </v-col>
                  <v-col class="pb-0">
                    <v-autocomplete
                      v-model="tutor"
                      dense
                      outlined
                      :items="tutors"
                      item-value="id"
                      item-text="datos_fiscales[0].nombre_o_razon_social"
                      return-object
                      @input="fillTutor"
                    />
                  </v-col>
                </v-row>
              </template>
            </kn-personal-info>

            <kn-form-subtitle title="Dirección" />
            <kn-address
              :address="tutorAddress"
              :fillFromSearch="fillFromSearch"
            />

            <kn-form-subtitle title="Información de contacto" />
            <kn-contact-info :model="tutorPersonalInfo" />
          </div>
          <!-- Fin informacion de tutor -->
          <!-- Datos fiscales de tutor -->
          <div v-if="valueDeterminate === 100">
            <kn-tax-info :model="tutorTaxInfo" />
          </div>
          <!-- Fin datos fiscales de tutor -->
          <kn-form-action-buttons
            :disableAccept="invalidFields"
            @accept="save"
            @cancel="cancel"
          />
        </v-col>
      </v-row>
    </v-container>
    <kn-back-to-top-button />
    <kn-local-alert
      v-model="showAlert"
      :alertText="alertText" 
      :alertType="alertType"
      :alertColor="alertColor"
      :loadingAlert="loading"
      @action1="actionAlertBtn1"
      @action2="continueAdding"
    />
  </section>
</template>

<script>
import KnFormActionButtons from '@/components/KnFormActionButtons.vue'
import KnFormSubtitle from '@/components/KnFormSubtitle.vue'
// import KnAddress from '@/components/forms/KnAddress.vue'
import KnContactInfo from '@/components/forms/KnContactInfo.vue'
import KnPersonalInfo from '@/components/forms/KnPersonalInfo.vue'
import KnSchoolInfo from '@/components/forms/KnSchoolInfo.vue'
import KnTaxInfo from '@/components/forms/KnTaxInfo.vue'
import { api } from '@/api/axios-base'
import { utilsMixin } from '@/mixins/utilsMixin'
import { generalRepoMixin } from '@/mixins/generalRepoMixin'
import { addressUtilsMixin } from '@/mixins/addressUtilsMixin'
import { generalFetchingMixin } from '@/mixins/generalFetchingMixin'
import { dateUtilsMixin } from '@/mixins/dateUtilsMixin'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import { apiMixin } from '@/mixins/apiMixin'
import { arrayUtilsMixin } from '@/mixins/arrayUtilsMixin'
import { searchUtilsMixin } from '@/mixins/searchUtilsMixin'
import KnLocalAlert from '@/components/KnLocalAlert.vue'
import KnTabs from '@/components/KnTabs.vue'
import { mapState } from 'vuex'
import KnBackToTopButton from '@/components/forms/KnBackToTopButton.vue'
import KnFormTitle from '@/components/KnFormTitle.vue'
import KnFormNoteOfMandatory from '@/components/KnFormNoteOfMandatory.vue'
import KnSelect from '../../components/inputs/KnSelect.vue'
import KnCheckBox from '../../components/inputs/KnCheckBox.vue'
const actionConsts = {
  ASSIGN: 1,
  REASSIGN: 2,
  PASS: 3,
  FAIL: 4,
  UNASSIGN: 5,
}
export default {
  components: {
    KnTaxInfo,
    KnPersonalInfo,
    KnAddress: () => import('@/components/forms/KnAddress.vue'),
    KnSchoolInfo,
    KnFormActionButtons,
    KnFormSubtitle,
    KnContactInfo,
    KnLocalAlert,
    KnTabs,
    KnBackToTopButton,
    KnFormTitle,
    KnFormNoteOfMandatory,
    KnSelect,
    KnCheckBox
  },
  mixins: [
    utilsMixin,
    generalRepoMixin,
    addressUtilsMixin,
    generalFetchingMixin,
    dateUtilsMixin,
    validationFormMixin,
    apiMixin,
    arrayUtilsMixin,
    searchUtilsMixin
  ],
  props: {
    entity: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      valueDeterminate: 33,
      routerName: 'Alumnos',
      row: null,
      tabs: [
        { name: 'Datos del alumno', value: 33 },
        { name: 'Datos del tutor', value: 66 },
        { name: 'Datos fiscales', value: 100 },
      ],
      loading: false,
      studentId: null,
      tutorId: null,
      studentPersonalInfo: {
        primer_nombre: null,
        segundo_nombre: '',
        apellido_paterno: null,
        apellido_materno: null,
        id_genero: null,
        fecha_nacimiento: null,
        edad: null,
        numero_identidad: '',
        id_institucion_educativa: null,
        numero_credencial_alumno: '',
        dateRange: {
          from: null,
          to: null
        },
      },
      studentAddress: {
        estado: null,
        ciudad: '',
        municipio: '',
        codigo_postal: null,
        colonia: null,
        calle: null,
        numero_exterior: null,
        numero_interior: '',
        numero_contacto: '',
      },
      tutorPersonalInfo: {
        primer_nombre: null,
        segundo_nombre: '',
        apellido_paterno: null,
        apellido_materno: null,
        id_genero: null,
        fecha_nacimiento: null,
        edad: null,
        numero_identidad: '',
        telefono_movil: null,
        email: null
      },
      tutorTaxInfo: {
        tax_id: null,
        nombre_o_razon_social: null,
        id_uso_factura: null
      },
      tutorAddress: {
        estado: null,
        ciudad: '',
        municipio: '',
        codigo_postal: null,
        colonia: null,
        calle: null,
        numero_exterior: null,
        numero_interior: '',
        numero_contacto: '',
      },
      // tutorTaxAddress: {
      //   numero_interior: ''
      // },
      tutor: null,
      tutors: [],
      fillFromSearch: false,
      fillStudentFromSearch: false,
      groupActions: [
        { text: 'Reasignar - mismo ciclo escolar/corrección de colegiatura', value: actionConsts.REASSIGN },
        { text: 'Reasignar - nuevo ciclo escolar', value: actionConsts.PASS },
        // { text: 'Recursar', value: actionConsts.FAIL },
        { text: 'Desasignar', value: actionConsts.UNASSIGN },
      ],
      selectedGroupAction: null,
      groups: [],
      tuition: [],
      promptPaymentDiscounts: [],
      penalties: [],
      debtStatus: [],
      openChargeInfo: false,
      selectedGroup: null,
      entityGroup: null,
      studentDebts: [],
      wildcardStudent: null,
      wildcardStudentDebts: [],
      checkInitialDebts: true
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    title() {
      return this.entity === null ? 'Agregar alumno' : 'Editar alumno'
    },
    isNewMode() {
      return (this.entity === null)
    },
    successAlertText() {
      return this.isNewMode ? 'Alumno registrado con exito!' : 'Alumno actualizado con exito!'
    },
    successAlertType() {
      return this.isNewMode ? 'success' : 'info'
    },
    assignGroupText() {
      return !this.hasGroup ? 'Asignar grupo' : 'Grupo'
    },
    invalidFields() {
      // console.log('isNewMode?', this.isNewMode);
      // console.log('studentPersonalInfo tiene nulls?', this.objectHasNulls(this.studentPersonalInfo), this.studentPersonalInfo)
      // console.log('studentAddress tiene nulls?', this.objectHasNulls(this.studentAddress), this.studentAddress)
      // console.log('tutorPersonalInfo tiene nulls?', this.objectHasNulls(this.tutorPersonalInfo), this.tutorPersonalInfo)
      // console.log('tutorAddress tiene nulls?', this.objectHasNulls(this.tutorAddress), this.tutorAddress)
      // console.log('tutorTaxInfo tiene nulls?', this.objectHasNulls(this.tutorTaxInfo), this.tutorTaxInfo)
      return this.isNewMode && (this.objectHasNulls(this.studentPersonalInfo)
              || this.objectHasNulls(this.studentAddress)
              || this.objectHasNulls(this.tutorPersonalInfo)
              || this.objectHasNulls(this.tutorAddress)
              || this.objectHasNulls(this.tutorTaxInfo))
    },
    hasGroup() {
      return this.studentPersonalInfo.grupo !== undefined 
              && this.studentPersonalInfo.grupo !== null
    },
    showGroupActions() {
      return !this.isNewMode && this.hasGroup
    },
    showAssignGroup() {
      return !this.hasGroup || (this.selectedGroupAction !== null 
                  && (this.selectedGroupAction === actionConsts.REASSIGN))
    },
    showPassGroup() {
      return !this.hasGroup || (this.selectedGroupAction !== null
        && (this.selectedGroupAction === actionConsts.PASS))
    },
    schoolCycleText() {
      return this.selectedGroup !== null
              ? `${this.selectedGroup.ciclo_escolar.fecha_inicio} - ${this.selectedGroup.ciclo_escolar.fecha_fin}`
              : ''
    },
    tuitionText() {
      return this.selectedGroup !== null
              ? `${this.entity.colegiatura.nombre_colegiatura} - $${this.entity.colegiatura.monto}` 
              : ''
    },
    // isTheSchoolYearDifferent() {
    //   if (!this.isNewMode && this.hasGroup) {
    //     if (this.entity.groupId !== this.selectedGroup.id) {
    //       const endSchoolYearEntityGroup = this.getDateWithTimeZeroStr(this.entityGroup.ciclo_escolar.fecha_fin)
    //       const startSchoolYearSelectedGroup = this.getDateWithTimeZeroStr(this.selectedGroup.ciclo_escolar.fecha_inicio)
    //       // console.log('Fin del anio escolar anterior', endSchoolYearEntityGroup);
    //       // console.log('Inicio del nuevo anio escolar', startSchoolYearSelectedGroup);
    //       // console.log('startSchoolYearSelectedGroup > endSchoolYearEntityGroup', startSchoolYearSelectedGroup > endSchoolYearEntityGroup);
    //       return startSchoolYearSelectedGroup > endSchoolYearEntityGroup
    //     } else {
    //       return false
    //     }
    //   }
    //   return false
    // }
  },
  async created() {
    this.loading = true
    this.alertText = 'Por favor, espere. Cargando...'
    this.showAlert = true
    this.alertType = 'info'
    const allTutors = await this.fetchResultsByEI('personas', 'tutor', this.institutionId)
    const allGroups = await this.fetchResultsByEI('personas', 'grupo', this.institutionId)
    this.tutors = this.activeItems(allTutors)
    this.groups = this.activeItems(allGroups)
    this.studentPersonalInfo.id_institucion_educativa = this.institutionId
    if (!this.isNewMode) {
      // console.log('Se deben llenar los campos');
      this.studentId = this.entity.id
      this.fillData()
    }
    this.showAlert = false
  },
  methods: {
    async postStudent(studentObj) {
      try {
        const response = await api.post('/personas/crear-alumno', studentObj)
        const responseData = await response.data
        // console.log('Respuesta alumno: ', responseData);
        if (responseData.e) {
          this.errors.push('No se pudo crear el alumno: ' + responseData.e.join(','))
          return
        }
        studentObj.id = responseData.id_objeto
        this.studentId = responseData.id_objeto
      } catch (error) {
        console.error('Error al intentar crear alumno.', error);
      }
    },
    async patchStudent(studentObj) {
      try {
        const response = await api.patch('/personas/update-alumno', studentObj)
        const responseData = await response.data
        // console.log('Respuesta actualizacion alumno: ', responseData);
        if (responseData.e) {
          this.errors.push('No se pudo actualizar el alumno: ' + responseData.e.join(','))
          return
        }
        studentObj.id = responseData.id_objeto
        this.studentId = responseData.id_objeto
      } catch (error) {
        console.error('Error al intentar actualizar alumno.', error);
      }
    },
    async postTutor(tutorObj) {
      try {
        const response = await api.post('/personas/crear-tutor', tutorObj)
        const responseData = await response.data
        // console.log('Respuesta tutor: ', responseData);
        if (responseData.e) {
          this.errors.push('No se pudo crear el tutor: ' + responseData.e.join(','))
          return
        }
        tutorObj.id = responseData.id_objeto
        this.tutorId = responseData.id_objeto
      } catch (error) {
        console.error('Error al intentar crear tutor.', error);
      }
    },
    async patchTutor(tutorObj) {
      try {
        const response = await api.patch('/personas/update-tutor', tutorObj)
        const responseData = await response.data
        // console.log('Respuesta actualizar tutor: ', responseData);
        if (responseData.e) {
          this.errors.push('No se pudo actualizar el tutor: ' + responseData.e.join(','))
          return
        }
        tutorObj.id = responseData.id_objeto
        this.tutorId = responseData.id_objeto
      } catch (error) {
        console.error('Error al intentar actualizar tutor.', error);
      }
    },
    async createStudent() {
      try {
        this.alertText = 'Creando alumno...'
        this.studentAddress.numero_contacto = this.tutorPersonalInfo.telefono_movil
        this.studentAddress.id_institucion_educativa = this.institutionId
        this.alertText = 'Creando dirección de alumno'
        await this.postAddress(this.studentAddress)
        // console.log('Direccion de alumno: ', this.studentAddress);

        this.studentPersonalInfo.id_direccion = this.studentAddress.id
        this.studentPersonalInfo.id_institucion_educativa = this.institutionId
        this.studentPersonalInfo.email = this.tutorPersonalInfo.email
        this.studentPersonalInfo.telefono_casa = this.tutorPersonalInfo.telefono_movil
        this.studentPersonalInfo.telefono_movil = this.tutorPersonalInfo.telefono_movil
        this.alertText = 'Creando datos personales de alumno'
        await this.postPersonalInfo(this.studentPersonalInfo)
        // console.log('Datos personales de alumno: ', this.studentPersonalInfo);

        const userObj = {
          nombre_usuario: this.generateUsername(this.studentPersonalInfo),
          password: this.generatePassword(this.studentPersonalInfo),
          correo: this.tutorPersonalInfo.email,
          nombre: this.studentPersonalInfo.primer_nombre,
          apellido_paterno: this.studentPersonalInfo.apellido_paterno,
          is_staff: false
        }
        this.alertText = 'Creando usuario de alumno'
        await this.postUser(userObj)
        // console.log('Usuario alumno: ', userObj);

        const studentObj = {
          id_usuario: userObj.id,
          id_datos_personales: this.studentPersonalInfo.id,
          id_institucion_educativa: this.institutionId,
          numero_credencial: this.studentPersonalInfo.numero_credencial_alumno
        }
        await this.postStudent(studentObj)
        // console.log('Alumno: ', studentObj)
      } catch (error) {
        this.errors.push('Error al intentar crear alumno')
        console.error('Error al intentar crear alumno.', error);
      }
    },
    async updateStudent() {
      try {
        this.alertText = 'Actualizando alumno...'
        this.studentAddress.numero_contacto = this.tutorPersonalInfo.telefono_movil
        this.studentAddress.id_institucion_educativa = this.institutionId
        this.alertText = 'Actualizando dirección de alumno'
        await this.updateAddress(this.studentAddress)
        // console.log('Direccion de alumno: ', this.studentAddress);

        this.studentPersonalInfo.id_direccion = this.studentAddress.id
        this.studentPersonalInfo.id_institucion_educativa = this.institutionId
        this.studentPersonalInfo.email = this.tutorPersonalInfo.email
        this.studentPersonalInfo.telefono_casa = this.tutorPersonalInfo.telefono_movil
        this.studentPersonalInfo.telefono_movil = this.tutorPersonalInfo.telefono_movil
        this.alertText = 'Actualizando datos personales de alumno'
        await this.updatePersonalInfo(this.studentPersonalInfo)
        // console.log('Datos personales de alumno: ', this.studentPersonalInfo);

        const studentObj = {
          id_alumno: this.studentId,
          id_usuario: this.entity.usuario.id,
          numero_credencial_alumno: this.studentPersonalInfo.numero_credencial_alumno,
          id_datos_personales: this.studentPersonalInfo.id,
          id_institucion_educativa: this.institutionId,
          id_colegiatura: this.studentPersonalInfo.colegiatura.id
        }
        await this.patchStudent(studentObj)
        // console.log('Alumno: ', studentObj)
      } catch (error) {
        this.errors.push('Error al intentar crear alumno')
        console.error('Error al intentar crear alumno.', error);
      }
    },
    async createTutor() {
      try {
        this.alertText = 'Creando tutor...'
        this.tutorAddress.numero_contacto = this.tutorPersonalInfo.telefono_movil
        this.tutorAddress.id_institucion_educativa = this.institutionId
        this.alertText = 'Creando dirección de tutor...'
        await this.postAddress(this.tutorAddress)
        // console.log('Direccion tutor: ', this.tutorAddress)

        this.tutorPersonalInfo.id_institucion_educativa = this.institutionId
        this.tutorPersonalInfo.id_direccion = this.tutorAddress.id
        this.tutorPersonalInfo.telefono_casa = this.tutorPersonalInfo.telefono_movil
        this.alertText = 'Creando datos personales de tutor...'
        await this.postPersonalInfo(this.tutorPersonalInfo)
        // console.log('Datos personales tutor: ', this.tutorPersonalInfo)

        this.alertText = 'Creando datos fiscales de tutor...'
        await this.postTaxInfo(this.tutorTaxInfo)
        // console.log('Datos fiscales tutor: ', this.tutorTaxInfo)

        const userObj = {
          nombre_usuario: this.generateUsername(this.tutorPersonalInfo),
          password: this.generatePassword(this.tutorPersonalInfo),
          correo: this.tutorPersonalInfo.email,
          nombre: this.tutorPersonalInfo.primer_nombre,
          apellido_paterno: this.tutorPersonalInfo.apellido_paterno,
          id: null,
          is_staff: false
        }
        this.alertText = 'Creando usuario de tutor...'
        await this.postUser(userObj)
        // console.log('Usuario tutor: ', userObj);
        this.alertText = `Usuario tutor: ${userObj.nombre_usuario} ${userObj.password}`

        const tutorObj = {
          id_usuario: userObj.id,
          id_datos_personales: this.tutorPersonalInfo.id,
          id_datos_fiscales: this.tutorTaxInfo.id,
          responsable_economico: true,
          id_institucion_educativa: this.institutionId,
          id_parentesco: this.tutorPersonalInfo.id_parentesco
        }
        await this.postTutor(tutorObj)
        // console.log('Tutor: ', tutorObj);
      } catch (error) {
        this.errors.push('Error al intentar crear tutor')
        console.error('Error al intentar crear tutor.', error);
      }
    },
    /**
     * Actualiza datos de tutor: Direccion, Datos personales,
     * Datos fiscales y persona Tutor
     */
    async updateTutor() {
      try {
        this.alertText = 'Actualizar tutor...'
        this.tutorAddress.numero_contacto = this.tutorPersonalInfo.telefono_movil
        this.tutorAddress.id_institucion_educativa = this.institutionId
        this.alertText = 'Actualizar dirección de tutor...'
        await this.updateAddress(this.tutorAddress)
        // console.log('Direccion tutor: ', this.tutorAddress)

        this.tutorPersonalInfo.id_institucion_educativa = this.institutionId
        this.tutorPersonalInfo.id_direccion = this.tutorAddress.id
        this.tutorPersonalInfo.telefono_casa = this.tutorPersonalInfo.telefono_movil
        this.alertText = 'Actualizando datos personales de tutor...'
        await this.updatePersonalInfo(this.tutorPersonalInfo)
        // console.log('Datos personales tutor: ', this.tutorPersonalInfo)

        this.alertText = 'Actualizando datos fiscales de tutor...'
        await this.updateTaxInfo(this.tutorTaxInfo)
        // console.log('Datos fiscales tutor: ', this.tutorTaxInfo)

        const tutorObj = {
          id: this.tutorId,
          id_usuario: this.tutor.usuario.id,
          id_datos_personales: this.tutorPersonalInfo.id,
          id_datos_fiscales: this.tutorTaxInfo.id,
          responsable_economico: true,
          id_institucion_educativa: this.institutionId,
          id_parentesco: this.tutorPersonalInfo.id_parentesco
        }
        await this.patchTutor(tutorObj)
        // console.log('Tutor: ', tutorObj);
      } catch (error) {
        this.errors.push('Error al intentar actualizar tutor')
        console.error('Error al intentar actualizar tutor.', error);
      }
    },
    /**
     * Asigna un alumno a un grupo y genera adeudos del ciclo escolar
     * @param {number} studentId Id del alumno a asignar
     * @param {number} groupId   Id del grupo
     */
    async assignStudentToGroup(studentId, groupId) {
      try {
        const response = await api.post('/personas/agregar-alumno-grupo', {
          id_alumno: studentId,
          id_grupo: groupId
        })
        const responseData = await response.data
        if (responseData.mensaje && responseData.mensaje === 'operación exitosa') {
          this.alertText = 'Alumno asignado a grupo exitosamente'
          /* 
            TODO: Se debe verificar si el ciclo escolar es diferente
            por que solo si es asi se deben generar los adeudos
            del ciclo escolar.
            Se puede verificar la fecha actual en la que se se hace
            el cambio de grupo, ya que si la fecha actual es mayor que
            la fecha de vencimiento del ultimo adeudo de colegiatura,
            que, basicamente representa el fin de ciclo escolar,
            entonces asi se podria reconocer cuando si crear nuevos
            adeudos.
          */
          try {
            // console.log('Date range: ', this.studentPersonalInfo.dateRange);
            const promptPayDiscount = parseFloat(this.studentPersonalInfo.prontoPago.porcentaje_descuento) 
            const subtotal = parseFloat(this.studentPersonalInfo.colegiatura.monto)
            // De momento se quita el calculo de impuestos
            // pero la idea es que pueda configurar. Es decir que
            // desde configuración se puede indicar cual será
            // el porcentaje de impuestos
            const taxes = this.calculateTaxes(subtotal, 0.0)
            const totalWithTaxes = this.calculateTotalWithTaxes(subtotal, taxes)
            const discount = this.calculateDiscount(totalWithTaxes, promptPayDiscount)
            const debtsObj = {
              comentarios: `${this.studentPersonalInfo.colegiatura.nombre_colegiatura} - ${this.schoolCycleText}`,
              fecha_inicio_ciclo: this.selectedGroup.ciclo_escolar.fecha_inicio,
              fecha_fin_ciclo: this.selectedGroup.ciclo_escolar.fecha_fin,
              dias_de_gracia: 10,
              id_alumno: this.studentId,
              id_moneda: 1, // MXN
              id_penalizacion: this.studentPersonalInfo.id_penalizacion, // Pago tardio
              id_institucion_educativa: this.studentPersonalInfo.id_institucion_educativa,
              id_descuento_pronto_pago: this.studentPersonalInfo.prontoPago.id,
              id_estatus_adeudo: this.studentPersonalInfo.id_estatus_adeudo,
              id_autor: this.userData.id,
              sub_total: subtotal,
              total_impuestos: taxes,
              total_descuento: discount,
              total_adeudo: totalWithTaxes,
              descuento_aplicado: false,
              expirado: false,
            }
            if (this.checkInitialDebts) {
              await this.createInitialDebts()
            }
            await this.generateSchoolYearDebts(debtsObj)
          } catch (error) {
            this.errors.push('Ocurrió un problema, no se pudieron crear los adeudos del ciclo escolar')
          }
        } else {
          this.errors.push('Ocurrió un problema, no se pudo asignar el alumno a grupo')
        }
      } catch (error) {
        this.errors.push('Error al intentar asignar alumno a grupo.')
        console.error('Error al intentar asignar alumno a grupo.', error);
      }
    },
    /**
     * Asigna un alumno a un grupo pero no generar adeudos
     * @param {number} studentId Id del alumno a asignar
     * @param {number} groupId   Id del grupo
     */
    async assignGroup(studentId, groupId) {
      try {
        const response = await this.postObj('/personas/agregar-alumno-grupo', {
            id_alumno: studentId,
            id_grupo: groupId
          },
          'asignar')
        if (response.mensaje && response.mensaje === 'operación exitosa') {
          this.alertText = 'Alumno asignado a grupo exitosamente'
        } else {
          this.errors.push('Ocurrió un problema, no se pudo asignar el alumno a grupo')
        }
      } catch (error) {
        this.errors.push('Error al intentar asignar alumno a grupo.')
        console.error('Error al intentar asignar alumno a grupo.', error);
      }
    },

    /**
     * Cambia el grupo al que esta asignado un alumno y si hay cambio
     * de colegiatura actualiza adeudos pendientes de pagos
     * @param {number} studentId  id del alumno a reasignar
     * @param {number} oldGroupId id del grupo actual
     * @param {number} newGroupId id del nuevo grupo
     * @param {number} oldTuitionId id de la colegiatura actual
     * @param {number} newTuitionId id de la nueva colegiatura
     */
    async reAssignGroup(studentId, oldGroupId, newGroupId, oldTuitionId, newTuitionId) {
      /**
       * Para reasignar se debe remover el grupo actual
       * y posteriormente asignar el nuevo grupo.
       * Se debe revisar si el nuevo grupo a asignar
       * forma parte de un nuevo ciclo escolar en cuyo
       * caso se mandan a crear los adeudos de ciclo
       * escolar y los adeudos iniciales.
       * Si no cambia de ciclo escolar entonces solo se
       * asigna si crear adeudos en este caso tambien
       * se debe revisar si no hay cambio de colegiatura
       * si hay cambio se deben actualizar los adeudos
       * con el nuevo monto y el comentario igual al nombre
       * de la colegiatura, solo para aquellos que esten
       * pendientes a partir de la fecha en que se este
       * realizando la actualizacion.
       */
      try {
        // console.log('Reasignar grupo', studentId, oldGroupId, newGroupId);
        await this.unAssignGroup(studentId, oldGroupId)
        await this.assignGroup(studentId, newGroupId)

        // Si hay cambio de colegiatura se asigna la nueva
        // al alumno y se actualizan los adeudos
        if ((oldTuitionId !== null && newTuitionId !== null) 
              && newTuitionId !== oldTuitionId) {
          // console.log('Hubo cambio de colegiatura');
          await this.assignTuitionToStudent(newTuitionId, studentId)

          for (const debt of this.studentDebts) {
            const promptPayDiscount = parseFloat(this.studentPersonalInfo.prontoPago.porcentaje_descuento) 
            const subtotal = parseFloat(this.studentPersonalInfo.colegiatura.monto)
            // De momento se quita el calculo de impuestos
            // pero la idea es que pueda configurar. Es decir que
            // desde configuración se puede indicar cual será
            // el porcentaje de impuestos
            const taxes = this.calculateTaxes(subtotal, 0.0)
            const totalWithTaxes = this.calculateTotalWithTaxes(subtotal, taxes)
            const discount = this.calculateDiscount(totalWithTaxes, promptPayDiscount)
            const debtToUpdate = {
              ...debt,
              comentarios: this.studentPersonalInfo.colegiatura.nombre_colegiatura,
              id_alumno: this.studentId,
              id_moneda: debt.moneda.id, // MXN
              id_penalizacion: debt.penalizacion.id, // Pago tardio
              id_institucion_educativa: debt.institucion_educativa.id,
              id_descuento_pronto_pago: debt.descuento_pronto_pago.id,
              id_estatus: debt.estatus.id,
              sub_total: subtotal,
              total_impuestos: taxes,
              total_descuento: discount,
              total_adeudo: totalWithTaxes,
            }

            const response = await this.postObj('/administracion/update-adeudos', debtToUpdate, 'actualizar')
            if (response.mensaje) {
              this.alertText = 'Adeudo actualizado exitosamente'
            }
          }
        }
      } catch (error) {
        console.error('Error al intentar reasignar grupo del alumno', error); 
      }
    },
    /**
     * Cambia el grupo al que esta asignado un alumno aplica
     * cuando se inicia un ciclo escolar, por lo tanto se
     * crean los adeudos de ciclo escolar
     * @param {number} studentId  id del alumno a reasignar
     * @param {number} oldGroupId id del grupo actual
     * @param {number} newGroupId id del nuevo grupo
     */
    async reAssignGroupForNewSchoolYear(studentId, oldGroupId, newGroupId) {
      /**
       * Para reasignar se debe remover el grupo actual
       * y posteriormente asignar el nuevo grupo.
       */
      try {
        // console.log('Reasignar grupo', studentId, oldGroupId, newGroupId);
        await this.unAssignGroup(studentId, oldGroupId)
        await this.assignStudentToGroup(studentId, newGroupId)
      } catch (error) {
        console.error('Error al intentar reasignar grupo del alumno', error);
      }
    },
    /**
     * Remueve a un alumno de un grupo
     * @param {number} studentId Id del alumno a quitar del grupo
     * @param {number} groupId   Id del grupo
     */
    async unAssignGroup(studentId, groupId) {
      try {
        // console.log('Desasignar grupo', studentId, groupId);
        const response = await this.postObj('/personas/remove-alumnos-grupo', {
          id_alumno: studentId,
          id_grupo: groupId
        },
        'remover')
        if (response.mensaje.includes('Alumno removido con éxito')) {
          this.alertText = 'Alumno desasignado de grupo exitosamente'
        }
      } catch (error) {
        this.errors.push('Error al intentar desasignar alumno de grupo')
        console.error('Error al intentar remover alumno de grupo', error);
      }
    },
    /**
     * Asigna tutor a un alumno
     * @param {number} tutorId Id del tutor a asignar
     * @param {number} studentId Id del alumno
     */
    async assignTutorToStudent(tutorId, studentId) {
      try {
        const response = await api.post('/personas/agregar-tutor', {
          id_alumno: studentId,
          id_tutor: tutorId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un problema, no se pudo asignar tutor a alumno')
          return
        }
      } catch (error) {
        this.errors.push('Error al intentar asignar tutor a alumno.')
      }
    },
    /**
     * Asigna colegiatura a un alumno
     * @param {number} tuitionId Id de la colegiatura a asignar
     * @param {number} studentId Id del alumno
     */
    async assignTuitionToStudent(tuitionId, studentId) {
      try {
        const response = await api.post('/personas/asignar-colegiatura', {
          id_alumno: studentId,
          id_colegiatura: tuitionId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un problema, no se pudo asignar colegiatura a alumno')
          return
        }
      } catch (error) {
        this.errors.push('Error al intentar asignar colegiatura a alumno.')
      }
    },
    /**
     * Genera adeudos de todo el ciclo escolar
     * @param {object} debtsObj Objeto de adeudo base para crear todos los adeudos
     */
    async generateSchoolYearDebts(debtsObj) {
      try {
        const response = await api.post('/administracion/crear-adeudos-ciclo-escolar', debtsObj)
        const responseData = await response.data
        // console.log('Respuesta Generar aduedos ciclo escolar: ', responseData);
        if (responseData.e && responseData.e.length > 0) {
          const errors = responseData.e.join(', ')
          this.errors.push(`No se pudieron crear adeudos del ciclo escolar: ${errors}`)
        } else {
          this.alertText = 'Adeudos del ciclo escolar creados'
        }
      } catch (error) {
        this.errors.push('Error al intentar crear adeudos del ciclo escolar')
        console.error('Error al intentar crear adeudos del ciclo escolar');
      }
    },
    /**
     * Crea un adeudo
     * @param {object} debtObj Objeto de adeudo a crear
     */
    async postDebt(debtObj) {
      try {
        const response = await api.post('/administracion/crear-adeudo', debtObj)
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error al intentar agregar adeudo')
        } else {
          this.alertText = 'Adeudo ' + debtObj.nombre_adeudo + ' creado'
        }
        // console.log('Respuesta crear adeudo.', responseData);
      } catch (error) {
        this.errors.push('Error al intentar crear adeudo: ' + error.toString())
        console.error('Error al intentar crear adeudo.', error);
      }
    },
    async createInitialDebts() {
      try {
        for (const debt of this.wildcardStudentDebts) {
          const debtToCreate = {
            ...debt,
            id_alumno: this.studentId,
            id_moneda: debt.moneda.id, // MXN
            id_penalizacion: debt.penalizacion.id, // Pago tardio
            id_institucion_educativa: this.institutionId,
            id_descuento_pronto_pago: debt.descuento_pronto_pago.id,
            id_estatus_adeudo: debt.estatus.id,
            id_autor: this.userData.id,
          }
          // console.log('Dentro de initialDebts - Adeudo a crear', debtToCreate);
          await this.postDebt(debtToCreate)
        }
      } catch (error) {
        this.errors.push('Error al intentar crear adeudos base' + error)
      }
    },
    async createObjects() {
      try {
        this.loading = true
        this.alertText = 'Cargando...'
        this.showAlert = true
        if (!this.fillFromSearch) {
          await this.createTutor()
        }
        if (!this.errors.length) {
          await this.createStudent()
          if (!this.errors.length) {
            await this.assignTutorToStudent(this.tutorId, this.studentId)
            if (this.selectedGroupAction === actionConsts.ASSIGN) {
              const groupId = this.selectedGroup.id
              await this.assignTuitionToStudent(this.studentPersonalInfo.colegiatura.id, this.studentId)
              await this.assignStudentToGroup(this.studentId, groupId)
            }
          }
        }

        if (this.errors.length) {
          this.alertType = 'error'
          this.alertText = this.errors.join(', ')
        } else {
          this.alertType = this.successAlertType
          this.alertColor = 'success'
          this.alertText = this.successAlertText
        }
        this.loading = false
      } catch (error) {
        console.error('Error al dar de alta Alumno: Alumno, Tutor y objetos relacionados.', error);
      }
    },
    async updateObjects() {
      try {
        this.loading = true
        this.alertText = 'Cargando...'
        this.showAlert = true
        await this.updateTutor()
        await this.updateStudent()
        /*TODO:
          Esta pendiente como validar el cambio de grupo
          y el cambio de tutor 
        */
        const groupId = this.selectedGroup ? this.selectedGroup.id : null
        const bkGroupId = this.entity.groupId ? this.entity.groupId : null
        const bkTuitionId = this.entity.colegiatura ? this.entity.colegiatura.id : null
        const tuitionId = this.studentPersonalInfo.colegiatura ? this.studentPersonalInfo.colegiatura.id : null
        // if (groupId !== undefined && groupId !== null
        //     && (this.entity.groupId !== groupId)) {
        //   console.log('Hubo cambio de grupo: ', this.entity.groupId, groupId);
        //   await this.assignStudentToGroup(this.studentId, groupId)
        // }
        switch(this.selectedGroupAction) {
          case actionConsts.ASSIGN:
            await this.assignStudentToGroup(this.studentId, groupId)
            break
          case actionConsts.REASSIGN:
            await this.reAssignGroup(this.studentId, bkGroupId, groupId, bkTuitionId, tuitionId)
            break
          case actionConsts.PASS:
            // Reasignar grupo en cambio de ciclo escolar
            await this.reAssignGroupForNewSchoolYear(this.studentId, bkGroupId, groupId)
            break
          case actionConsts.FAIL:
            // console.log('Reprobar alumno');
            break
          case actionConsts.UNASSIGN:
            await this.unAssignGroup(this.studentId, groupId)
            break
          // default: console.log('Nada por hacer');
        }

        this.loading = false
        if (this.errors.length) {
          this.alertType = 'error'
          this.alertText = this.errors.join(', ')
        } else {
          this.alertType = this.successAlertType
          this.alertColor = 'success'
          this.alertText = this.successAlertText
        }
      } catch (error) {
        console.error('Error al actualizar Alumno: Alumno, Tutor y objeto relacionados.', error);
      }
    },
    setTabValue(val) {
      this.valueDeterminate = val
    },
    async save() {
      if (this.isNewMode) {
        this.createObjects()
      } else {
        this.updateObjects()
      }
    },
    cancel() {
      this.returnToTable()
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType  === 'info') {
        this.returnToTable()
      } else {
        this.closeAlert()
      }
    },
    continueAdding() {
      this.clean()
      this.closeAlert()
    },
    returnToTable() {
      this.$router.replace({name: this.routerName})
    },
    closeAlert() {
      this.errors = []
      this.showAlert = false
    },
    clean() {},
    calculateTaxes(value, tax) {
      return value * tax
    },
    calculateDiscount(value, discount) {
      return value * discount
    },
    calculateTotalWithTaxes(value, taxes) {
      return value + taxes
    },
    calculateTotalWithDiscount(value, discount) {
      return value - discount
    },
    async fillTutor() {
      this.fillFromSearch = false
      // console.log('Tutor seleccionado: ', this.tutor);
      this.tutorId = this.tutor.id
      // eslint-disable-next-line no-unused-vars
      const { numero_identidad_CURP, ...rest } = this.tutor.datos_personales 
      this.tutorPersonalInfo = {...rest}
      this.tutorPersonalInfo.responsable_economico = this.tutor.responsable_economico
      this.tutorPersonalInfo.id_genero = this.tutor.datos_personales.genero
      this.tutorPersonalInfo.id_parentesco = this.tutor.parentesco.id
      this.tutorPersonalInfo.numero_identidad_curp = ''
      const tutorAddress = await this.fetchAddresById(this.tutorPersonalInfo.direccion, this.institutionId)
      this.tutorAddress = {...tutorAddress}
      this.tutorAddress.id_codigo_postal = tutorAddress.codigo_postal.id
      this.tutorAddress.municipio = tutorAddress.codigo_postal.nombre_municipio
      // this.tutorTaxAddress = {...tutorAddress}
      this.tutorTaxInfo = {...this.tutor.datos_fiscales[0]}
      this.tutorTaxInfo.id_uso_factura = this.tutorTaxInfo.uso_factura
      this.fillFromSearch = true
    },
    async fillData() {
      this.fillStudentFromSearch = false
      // eslint-disable-next-line no-unused-vars
      const { numero_identidad_CURP, ...rest } = this.entity.datos_personales
      this.studentPersonalInfo = {...rest}
      this.studentPersonalInfo.id_genero = this.entity.datos_personales.genero
      this.studentPersonalInfo.numero_credencial_alumno = this.entity.numero_credencial_alumno
      this.studentPersonalInfo.numero_identidad_curp = ''
      this.studentPersonalInfo.dateRange = {from: null, to: null}
      this.studentPersonalInfo.id_institucion_educativa = this.entity.institucion_educativa.id
      this.studentPersonalInfo.colegiatura = {...this.entity.colegiatura}
      this.studentPersonalInfo.grupo = this.entity.groupId
      const studentAddress = await this.fetchAddresById(this.studentPersonalInfo.direccion, this.institutionId)
      this.studentAddress = {...studentAddress}
      this.studentAddress.id_codigo_postal = studentAddress.codigo_postal.id
      this.studentAddress.municipio = studentAddress.codigo_postal.nombre_municipio
      this.fillStudentFromSearch = true
      const tutorId = this.entity.tutores.length ? this.entity.tutores[0].id : null
      this.tutor = this.tutors.find(tutor => tutor.id === tutorId)
      await this.fillTutor()

      if (this.entity.groupId) {
        await this.fillGroupRelatedArrays()
        this.selectedGroup = this.groups.find(group => group.id === this.entity.groupId)
        this.entityGroup = this.selectedGroup
        // console.log('Se encontro el grupo objeto en el array de grupos', this.selectedGroup);
        const today = new Date()
        const todayWithTimeZero = this.getDateWithTimeZero(today)
        
        const debtsResponse = await this.postObj('/administracion/adeudos-por-alumno-ciclo-escolar', {
            id_alumno: this.studentId,
            id_ciclo_escolar: this.selectedGroup.ciclo_escolar.id
          },
          'obtener'
        )
        const debtsItems = await debtsResponse.resultado
        const tuitionDebts = debtsItems.filter(debt => {
          const vencimiento = this.getDateWithTimeZeroStr(debt.fecha_vencimiento)
          const tuitionName = this.entity.colegiatura.nombre_colegiatura
          return vencimiento > todayWithTimeZero && debt.comentarios.includes(tuitionName)
        })
        // console.log('Adeudos Alumno:', debtsItems);
        // console.log('Adeudos de colegiaturas que aun no han vencido:', tuitionDebts);

        /** Se deberian mostrar los adeudos? */
  
        if (tuitionDebts && tuitionDebts.length !== 0) {
          this.studentDebts = [...tuitionDebts]
          const firstTuitionDebt = tuitionDebts[0]
          this.studentPersonalInfo.prontoPago = firstTuitionDebt.descuento_pronto_pago // Es necesario establecer el pronto pago como objeto
          this.studentPersonalInfo.id_penalizacion = firstTuitionDebt.penalizacion.id
          this.studentPersonalInfo.id_estatus_adeudo = firstTuitionDebt.estatus.id
        }
      }
    },
    async fillGroupRelatedArrays() {
      try {
        if (!this.tuition.length 
            && !this.promptPaymentDiscounts.length 
            && !this.penalties.length
            && !this.debtStatus.length) {
          // console.log('Se deben llenar los arrays relacionados a grupo');
          const allTuition = await this.fetchResultsByEI('personas', 'colegiatura', this.institutionId)
          const allPromptPaymentDiscounts = await this.fetchResultsByEI('administracion', 'descuento-pronto-pago', this.institutionId)
          const allPenalties = await this.fetchResultsByEI('administracion', 'penalizacion', this.institutionId)
          const allDebtStatus = await this.fetchResultsByEI('administracion', 'estatus-adeudos', this.institutionId)
          const activeDebtStatus = this.activeItems(allDebtStatus)

          this.tuition = this.activeItems(allTuition)
          this.promptPaymentDiscounts = this.activeItems(allPromptPaymentDiscounts)
          this.penalties = this.activeItems(allPenalties)
          this.debtStatus = activeDebtStatus.filter(dStatus => dStatus.dato.toLowerCase().includes('pendiente de pago'))
        }
        // console.log('Se deben llenar los campos relacionado a grupo');
      } catch (error) {
        console.error('Error al intentar llenar los arrays relacionados a grupo');
      }
    },
    async getDebtsOfWildcardStudent() {
      try {
        this.wildcardStudent = await this.searchStudentByName('comodin')
        if (this.wildcardStudent !== null ) {
          const response = await api.post('/administracion/adeudos-por-alumno-ciclo-escolar',
            {
                id_alumno: this.wildcardStudent.id,
                id_ciclo_escolar: this.selectedGroup.ciclo_escolar.id
            })
          const responseData = await response.data
          if (responseData.e) {
            return { error: 'No se pudieron obtener los adeudos del alumno comodin' }
          }
          
          this.wildcardStudentDebts = [...responseData.resultado]
        }
      } catch (error) {
        console.error('Error al intentar obtener los adeudos del alumno comodin');
      }
    },
    async loadGroupInfo() {
      await this.fillGroupRelatedArrays()
      await this.getDebtsOfWildcardStudent()
      this.openChargeInfo = true
      if (this.selectedGroupAction === null) {
        this.selectedGroupAction = actionConsts.ASSIGN
      }
    }
  }
}
</script>

<style>
.v-text-field .v-input__slot {
  border-radius: unset;
}
.border {
  border-color: gray;
  border-style: solid;
  border-width: 1px;
}
</style>