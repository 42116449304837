<template>
  <section>
    <kn-select
      label="Institución educativa"
      v-model="model.id_institucion_educativa"
      :rules="[rules.required]"
      :items="institutions"
      item-text="nombre_comercial"
      item-value="id"
    />
    <kn-text-field
      label="Número credencial"
      v-model="model.numero_credencial_alumno"
      :rules="[]"
      :isMandatory="false"
    />
  </section>
</template>

<script>
import { api } from '@/api/axios-base';
import { validationFormMixin } from '@/mixins/validationFormMixin'
import KnSelect from '../inputs/KnSelect.vue';
import KnTextField from '../inputs/KnTextField.vue';
export default {
  components: { KnSelect, KnTextField },
  mixins: [validationFormMixin],
  props: {
    model: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      row: null,
      institutions: [],
    }
  },
  async created() { 
    this.institutions = await this.fetchEducationalInstitution()
  },
  methods: {
    async fetchEducationalInstitution() {
      try {
        const response = await api.get('/personas/api/institucion-educativa-all/')
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al obtener intitutos educativos.', error);
      }
    }
  }
}
</script>

<style>

</style>